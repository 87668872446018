import cx from "classnames";
import _get from 'lodash/get';
import { FormattedMessage } from "react-intl";
import { animated } from 'react-spring';
import { useBlockAnimate } from '@/shared/hooks/useBlockAnimate';
import { useNavigation } from '@/processes/Router';

type IntroProps = {
  className: string;
  titleKey?: string;
  descKey?: string;
}

const Intro = (props: IntroProps) => {
  const { 
    className,
    titleKey = 'l.shared.title',
    descKey = 'l.shared.desc',
  } = props;
  const { ref: ref1, springStyles: springTitleStyles1 } = useBlockAnimate({ intersect: true, delay: 200, top: 25, once: true });
  const { coin, coinTitle } = useNavigation();

  return (
    <div ref={ref1} className={cx(className)}>
      <animated.h1 className="text-5xl/4 sm:text-8xl font-bold mb-8 sm:mb-12" style={springTitleStyles1}>
        <FormattedMessage
          id={titleKey}
          values={{
            span: (...chunks) => (
              <span className="theme-text-highlight">{chunks}</span>
            ),
            br: <br/>,
            coin,
            coinTitle,
          }}
        />
      </animated.h1>
   
      <animated.p 
        className="text-base sm:text-xl my-8 sm:my-16 leading-6"
        style={springTitleStyles1}
      >
        <FormattedMessage id={descKey} values={{
          coinTitle, 
        }}/>
      </animated.p>
      
    </div>
  );
};

export default Intro;
