import s from './Table.module.scss';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import cx from 'classnames';
import { getPower } from '@/shared/utils/formatters';
import { toFixed } from '@/shared/utils/toFixed'
import { useNavigation } from '@/processes/Router';
import { useCryptoCurrencies, useCoinsStats } from "@/processes/PoolStats";
import Link from 'next/link';
import CoinIcon from "@/shared/ui/CoinIcon";
import { COIN_LANDING } from "@/processes/Router";
import _toLower from "lodash/toLower";

type PoolListProps = {
  className?: string;
}

const Table = (props: PoolListProps) => {
  const { coin: currentCoin } = useNavigation();
  const { cryptoCurrencies } = useCryptoCurrencies();
  const { coinsStats } = useCoinsStats();
  
  const { className } = props;
  return (
    <div className={cx(s.root, className)}>
      <table className={s.table}>
        <thead>
          <tr>
            <th/>
            <th><FormattedMessage id="Price"/></th>
            <th><FormattedMessage id="Daily Earnings"/></th>
            <th><FormattedMessage id="rewardType.text"/></th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {cryptoCurrencies.map((currency: any) => {
            const { name: coin, profitPerPower } = currency;
            const {
              expectedReward24H,
              exchangeRates,
            } = coinsStats[coin];
            const isCurrentCoin = currentCoin === coin;
            const powerFormatted = getPower(profitPerPower, 0);
            const price = exchangeRates.USD;
            const rewardFiat = toFixed(exchangeRates.USD * expectedReward24H, 2);
            const href =  `/${_get(COIN_LANDING, _toLower(coin), '')}`;
            const isBTC = coin === 'BTC';
            const isGRAM = coin === 'GRAM';
            return (
              <tr key={coin}>
                <td>
                  <CoinIcon coin={coin} className="w-5 h-5" />
                </td>
                <td>
                  ${isGRAM ? toFixed(price, 5) : price}
                </td>
                <td>
                  ${rewardFiat}
                  {' '}
                  <span className="text-xs">
                    /
                    {' '}
                    {powerFormatted.value}
                    {' '}
                    {powerFormatted.suffix}
                  </span>
                </td>
                <td>
                  { isBTC ? <FormattedMessage id="rewardType.FPPS"/> : isGRAM ? <FormattedMessage id="rewardType.PPLNS"/> : <FormattedMessage id="rewardType.PPS+"/> }
                </td>
                <td>
                  {!isCurrentCoin &&
                    <Link className={s.more} href={href}>
                      <FormattedMessage id="Learn more"/>
                    </Link>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
